<template>
    <BarometerUI
        ref="UI"
        :skin="skin"
        :dataModel="dataModel"
        :showLoading="showLoading"
        :errorCode="error.code"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        @settings-changed="changeSettings"
    >
    </BarometerUI>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import BarometerUI from './barometer-ui';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-barometer',
    components: {
        BarometerUI
    },
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    data() {
        return {
            defaultConfig: {
                settings: {
                    autoHeight: false,
                    showHeader: false,
                    showBorder: false,
                    languageId: 'en-US',
                    updateLastAccessTime: true,
                    skin: '',
                    dataType: '',
                    showMenu: true,
                    showLabels: true,
                    dateRangeList: [
                        'oneDay',
                        'oneWeek',
                        'oneMonth',
                        'threeMonth',
                        // 'sixMonth',
                        // 'ytd',
                        'oneYear',
                        'threeYear'
                        // 'fiveYear',
                        // 'tenYear',
                        // 'fifteenYear'
                    ],
                    dateRange: 'oneDay',
                    maxLegendWidth: '360'
                },
                labels,
                format: {
                    number: {
                        dataType: 'number',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    },
                    date: {
                        dataType: 'date',
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric'
                    },
                    dateTime: {
                        dataType: 'date',
                        hour: 'numeric',
                        minute: 'numeric',
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                        hour12: true
                    }
                },
                intlNamespace: 'mwc-markets-barometer'
            },
            dataModel: [],
            showLoading: false
        };
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    created() {
        this.dateRangeList = [
            'oneDay',
            'oneWeek',
            'oneMonth',
            'threeMonth',
            'sixMonth',
            'ytd',
            'oneYear',
            'threeYear',
            'fiveYear',
            'tenYear',
            'fifteenYear'
        ];
        this.pullSubscribePromise = null;
        this.streamSubscribePromise = null;
        this.initialized = false;
        this.targetVisibility = false;
        this.subscribeList = [
            '0P00001GJR',
            '0P00001GJO',
            '0P00001GJL',
            '0P00001GJS',
            '0P00001GJP',
            '0P00001GJM',
            '0P00001GJT',
            '0P00001GJQ',
            '0P00001GJN'
        ];
        this.changeDataType(this.settings.dataType);
    },
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
        if (!utils.isHidden(this.$el) && !this.initialized) {
            this.initialize();
        }
    },
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        if (this.isSubscribing) {
            this._unsubscribe(this.currentDataType, this.subscribeList);
            this.isSubscribing = false;
        }
    },
    methods: {
        initialize() {
            this.initialized = true;
            if (this.subscribeList.length) {
                this.showLoading = true;
                this._getSnapShot(this.subscribeList).then(() => {
                    if (!this.error.code) {
                        this.renderCompleted = true;
                    }
                });
                this._subscribe(this.currentDataType, this.subscribeList);
            }
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialize();
                } else {
                    this._subscribe(this.currentDataType, this.subscribeList);
                }
            } else {
                if (!this.targetVisibility) {
                    if (this.isSubscribing) {
                        this._unsubscribe(
                            this.currentDataType,
                            this.subscribeList
                        );
                        this.isSubscribing = false;
                    }
                }
                this.targetVisibility = false;
            }
        },
        changeTargetElement() {
            this.targetVisibility = true;
        },
        changeDataType(value) {
            if (!this.mktdata) {
                return;
            }
            const oldValue = this.currentDataType;
            this.currentDataType = value === 'stream' ? value : 'pull';
            if (!this[`${this.currentDataType}SubscribePromise`]) {
                this[`${this.currentDataType}SubscribePromise`] = this.mktdata[
                    this.currentDataType
                ]();
            }
            if (oldValue) {
                this._unsubscribe(oldValue, this.subscribeList);
            }
            this._subscribe(this.currentDataType, this.subscribeList);
        },
        updateData(security, updates) {
            const index = utils.findIndex(
                this.dataModel,
                f => f.queryKey === security
            );
            if (index > -1) {
                const intraday = this._buildIntraday(updates);
                if (!utils.isEmptyObject(intraday)) {
                    const securityItem = utils.extend(
                        {},
                        this.dataModel[index],
                        intraday
                    );
                    this.$set(this.dataModel, index, securityItem);
                }
            }
        },
        _subscribe(dataType, symbols) {
            if (
                dataType &&
                symbols &&
                this[`${dataType}SubscribePromise`] &&
                this.initialized
            ) {
                this.isSubscribing = true;
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.subscribe(symbols, this.subscribeListener, {
                            updateLastAccessTime: this.settings
                                .updateLastAccessTime
                        });
                    })
                    .catch(e => {});
            }
        },
        _unsubscribe(dataType, symbols) {
            if (dataType && symbols && this[`${dataType}SubscribePromise`]) {
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.unsubscribe(symbols, this.subscribeListener);
                    })
                    .catch(e => {});
            }
        },
        _setError(error) {
            this._unsubscribe(this.currentDataType, this.subscribeList);
            this.error = error;
            this.dataModel = [];
            this.showLoading = false;
        },
        _getSnapShot(securities) {
            if (!this.mktdata) {
                this.showLoading = false;
                return;
            }
            const promiseList = [
                this.mktdata.quotes({
                    securities
                }),
                this.mktdata.staticData({
                    securities
                }),
                this.mktdata.securities(securities).catch(() => {
                    return Promise.resolve([]);
                })
            ];

            return Promise.all(promiseList)
                .then(res => {
                    const [quotes, statics, secueities] = res;
                    securities.forEach(security => {
                        const qt = utils.find(
                            quotes,
                            q => q.security === security
                        );
                        const st = utils.find(
                            statics,
                            s => s.security === security
                        );

                        const sec = utils.find(
                            secueities,
                            s => s.queryKey === security
                        );
                        this._setDataModel(st, qt, sec);
                    });
                    this.showLoading = false;
                })
                .catch(e => {
                    this._setError(this.ERROR_TYPES.NODATA);
                });
        },
        _setDataModel(staticData, quoteData, secData) {
            this.showLoading = false;
            const security = staticData.security;
            const statics = staticData.statics;
            const _dataModel = {};
            const dataObject = {};
            statics.forEach(f => {
                const name = f.name.replace('Return', '');
                if (
                    f.name === 'returnEndDate' ||
                    utils.findIndex(this.dateRangeList, dateRange => {
                        return dateRange === name;
                    }) > -1
                ) {
                    dataObject[name] = f.value;
                }
                _dataModel[security] = dataObject;
            });
            if (quoteData && secData) {
                const chgp = utils.find(
                    quoteData.quotes,
                    q => q.name === 'chg%'
                );
                _dataModel[security][this.dateRangeList[0]] = chgp
                    ? chgp.value
                    : undefined;
                const tradeDateTime = utils.find(
                    quoteData.quotes,
                    q => q.name === 'tradeDateTime'
                );
                if (tradeDateTime) {
                    _dataModel[security]['lastTradeDateTime'] =
                        tradeDateTime.value;
                    // if there is no tradeDateTime, no need timezoneAbbreviation
                    _dataModel[security]['timezoneAbbreviation'] =
                        secData.timezoneAbbreviation;
                }
            }
            _dataModel[security]['queryKey'] = security;
            this.dataModel.push(_dataModel[security]);
        },
        _buildIntraday(quotes) {
            const ret = {};
            if (quotes && quotes.length) {
                quotes.forEach(q => {
                    let value = q.value;
                    if (q.name === 'tradeDateTime') {
                        ret['lastTradeDateTime'] = value;
                    } else if (q.name === 'chg%') {
                        ret[this.dateRangeList[0]] = value;
                    }
                });
            }
            return ret;
        }
    }
};
</script>

<style></style>
