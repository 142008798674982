<template>
    <markets-ui-container
        :class="classes"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
    >
        <div
            ref="content"
            slot="content"
            :class="namespace('content')"
            v-if="!errorMessage"
        >
            <div
                ref="menu"
                v-if="watchedSettings.showMenu"
                :class="[
                    namespace('menu'),
                    chartClass ? namespace(`menu-${chartClass}`) : ''
                ]"
            >
                <markets-ui-menus
                    :skin="watchedSettings.skin"
                    :dataModel="dateRangeDataModel"
                    :aria-label="initedLabels.timePeriod"
                    @change="changeDateRange"
                >
                </markets-ui-menus>
            </div>
            <div
                ref="chart"
                role="figure"
                :aria-describedby="descriptionId"
                :class="[
                    namespace('chart'),
                    chartClass ? namespace(`chart-${chartClass}`) : ''
                ]"
                :style="chartStyles"
            >
                <span
                    :id="descriptionId"
                    :class="namespace('chart-desc-hidden')"
                    >{{ chartDescription }}</span
                >
                <div
                    v-for="d in dataSet"
                    :key="d.id"
                    :class="d.cls"
                    aria-hidden="true"
                >
                    <span v-if="showValues" :style="d.style">{{
                        d.value
                    }}</span>
                </div>
            </div>
            <div
                ref="legend"
                :class="namespace('legend')"
                :style="legendStyles"
                aria-hidden="true"
            >
                <div
                    v-if="watchedSettings.showLabels"
                    :class="namespace('legend-placeholder')"
                ></div>
                <div :class="namespace('legend-wrap')">
                    <div :class="namespace('legend-color')">
                        <span
                            v-for="c in legendColorSet"
                            :key="c"
                            :class="namespace(c)"
                        ></span>
                    </div>
                    <div :class="namespace('legend-value')">
                        <span v-for="v in legendValueSet" :key="v.id">
                            {{ v.value }}
                        </span>
                    </div>
                </div>
            </div>
            <div ref="dateReference" :class="[namespace('date-reference')]">
                <div
                    v-if="watchedSettings.showLabels"
                    :class="namespace('date-reference-placeholder')"
                ></div>
                <div :class="namespace('date-reference-wrap')">
                    {{ dataAsOf }}
                </div>
            </div>
        </div>
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import { colorSet, getFillColor, getRange } from './metadata/data-range.js';
const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-barometer-ui',
    mixins: [mixins.componentUI],
    props: {
        dataModel: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            chartDimension: 0,
            boxDimension: 0,
            showShortLabel: false,
            showValues: true,
            chartClass: ''
        };
    },
    computed: {
        classes() {
            const cls = [this.namespace()];
            if (!this.watchedSettings.showLabels) {
                cls.push(this.namespace('__no-labels'));
            }
            return cls;
        },
        dateRangeDataModel() {
            return this.watchedSettings.dateRangeList.map(dateRange => {
                return {
                    id: dateRange,
                    name: this.initedLabels[dateRange],
                    selected: dateRange === this.watchedSettings.dateRange
                };
            });
        },
        chartStyles() {
            const style = {
                height: `${this.chartDimension}px`
            };
            if (!this.watchedSettings.autoHeight) {
                style.width = `${this.chartDimension}px`;
            }
            return style;
        },
        legendStyles() {
            let paddings = 0;
            if (this.watchedSettings.autoHeight && this.chartPaddings) {
                paddings = this.chartPaddings.left + this.chartPaddings.right;
            }
            return {
                width: `${Math.min(
                    this.watchedSettings.maxLegendWidth,
                    this.chartDimension - paddings
                )}px`
            };
        },
        chartDescription() {
            let desc = '';
            this.dataSet.forEach(item => {
                if (item.ariaLabel) {
                    desc += `${item.ariaLabel}:${item.value}; `;
                }
            });
            return desc;
        },
        dataSet() {
            if (this.dataModel.length === 0) {
                return [];
            }
            const data = [];
            const position = ['large', 'mid', 'small'];
            const types = ['value', 'core', 'barometerGrowth'];
            if (this.watchedSettings.showLabels) {
                ['empty', ...types].forEach(id => {
                    data.push({
                        id,
                        value: this.getChartLabel(id),
                        cls: [
                            this.namespace('chart-label'),
                            id !== 'empty'
                                ? this.namespace('chart-label-h')
                                : '',
                            this.namespace(`chart-label-${id}`)
                        ]
                    });
                });
            }
            this.dataModel.forEach((d, i) => {
                const rowIndex = parseInt(i / 3);
                if (this.watchedSettings.showLabels && i % 3 === 0) {
                    data.push({
                        id: position[i],
                        value: this.getChartLabel(position[rowIndex]),
                        cls: [
                            this.namespace('chart-label'),
                            this.namespace('chart-label-v'),
                            this.namespace(`chart-label-${position[rowIndex]}`)
                        ]
                    });
                }
                const value = d[this.watchedSettings.dateRange] || 0;
                data.push({
                    id: d.security,
                    value: utils.getFormatValue({
                        formatter: this.formatter,
                        value,
                        dataType: 'number'
                    }),
                    ariaLabel: `${this.getChartLabel(
                        position[rowIndex]
                    )}-${this.getChartLabel(types[i % 3])}`,
                    cls: [
                        this.namespace('chart-box'),
                        this.namespace(`chart-box-${i + 1}`),
                        this.namespace(
                            getFillColor(this.watchedSettings.dateRange, value)
                        )
                    ],
                    style: {
                        'font-size': `${this.boxDimension * 0.38}px`
                    }
                });
            });
            return data;
        },
        legendValueSet() {
            const range = getRange(this.watchedSettings.dateRange);
            return [
                {
                    id: 'negative',
                    value: `\u2264 ${range[0]}%`
                },
                {
                    id: 'zero',
                    value: '0'
                },
                {
                    id: 'positive',
                    value: `${range[1]}% \u2265`
                }
            ];
        },
        dataAsOf() {
            let intraday = {};
            let eod;
            this.dataModel.forEach(item => {
                const lastTradeDateTime = intraday.lastTradeDateTime || '';
                if (item.lastTradeDateTime > lastTradeDateTime) {
                    intraday = item;
                }
                if (!eod) {
                    eod = item.returnEndDate;
                }
            });
            let ret = '';
            if (
                this.watchedSettings.dateRange === 'oneDay' &&
                intraday.lastTradeDateTime
            ) {
                ret = `${this.initedLabels.dataAsOf} ${utils.getFormatValue({
                    formatter: this.formatter,
                    dataType: 'dateTime',
                    value: intraday.lastTradeDateTime
                })} ${intraday.timezoneAbbreviation}`;
            } else if (eod) {
                ret = `${this.initedLabels.dataAsOf} ${utils.getFormatValue({
                    formatter: this.formatter,
                    dataType: 'date',
                    value: eod
                })}`;
            }
            return ret;
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('barometer');
        this.legendColorSet = colorSet;
        this.lphWidth = -1;
        this.lphMarginRight = 0;
        this.throttleResize = utils.throttle(contentRect => {
            this.chartDimension = this.calculateChartDimension();
            this.$nextTick(() => {
                this.boxDimension = this.calculateChartBoxDimension();
            });

            this.handleBreakPoint();
        }, 500);
        this.descriptionId = `desc_${utils.guid()}`;
    },
    mounted() {
        this.contentInnerRect = utils.innerSizes(this.$refs.content);
        this.chartPaddings = utils.getPaddings(this.$refs.chart);
        this.chartDimension = this.calculateChartDimension();
        utils.resizeObserver.observe(this.$el, this._resizeObserverCallback);
    },
    beforeDestroy() {
        utils.resizeObserver.unobserve(this.$el, this._resizeObserverCallback);
    },
    methods: {
        _resizeObserverCallback() {
            this.throttleResize();
        },
        handleBreakPoint() {
            // need add paddings to check breakpoint
            const chartDimension =
                this.chartDimension +
                this.chartPaddings.left +
                this.chartPaddings.right;
            if (chartDimension <= 140) {
                // The values within the cells are removed.
                this.showValues = false;
                this.showShortLabel = true;
                this.chartClass = 'less140';
            } else if (chartDimension > 140 && chartDimension <= 225) {
                // Labels are removed.
                this.showValues = true;
                this.showShortLabel = true;
                this.chartClass = 'less225';
            } else if (chartDimension > 225 && chartDimension <= 285) {
                // The labels abbreviate and their size and internal padding reduce again.
                this.showValues = true;
                this.showShortLabel = true;
                this.chartClass = 'less285';
            } else if (chartDimension > 285 && chartDimension <= 400) {
                // The label sizes and internal padding reduce again.
                this.showValues = true;
                this.showShortLabel = false;
                this.chartClass = 'less400';
            } else if (chartDimension > 400 && chartDimension <= 490) {
                // The label sizes reduce, and internal padding between elements tightens
                this.showValues = true;
                this.showShortLabel = false;
                this.chartClass = 'less490';
            } else {
                this.showValues = true;
                this.showShortLabel = false;
                this.chartClass = '';
            }
        },
        getChartLabel(id) {
            if (this.watchedSettings.showLabels) {
                if (this.showShortLabel) {
                    const cap_id = id
                        .toLowerCase()
                        .replace(/( |^)[a-z]/g, L => L.toUpperCase());
                    return this.initedLabels[`short${cap_id}`];
                } else {
                    return this.initedLabels[id];
                }
            }
            return '';
        },
        calculateMenuHeight() {
            if (this.watchedSettings.showMenu) {
                return utils.outerSizes(this.$refs.menu, true).height;
            }
            return 0;
        },
        calculateChartDimension() {
            const contentRect = utils.innerSizes(this.$refs.content);
            if (this.watchedSettings.autoHeight) {
                return contentRect.width;
            } else {
                return (
                    Math.min(contentRect.width, contentRect.height) -
                    this.calculateMenuHeight() -
                    utils.outerSizes(this.$refs.legend, true).height -
                    utils.outerSizes(this.$refs.dateReference, true).height -
                    this.chartPaddings.top -
                    this.chartPaddings.bottom
                );
            }
        },
        calculateChartBoxDimension() {
            if (this.lphWidth < 0 && this.$refs.legend) {
                const legendPlaceHolder = this.$refs.legend.querySelector(
                    `.${this.namespace('legend-placeholder')}`
                );
                if (legendPlaceHolder) {
                    this.lphWidth = utils.innerSizes(legendPlaceHolder).width;
                    this.lphMarginRight = utils.getMargins(
                        legendPlaceHolder
                    ).right;
                    return (
                        (this.chartDimension -
                            this.lphWidth -
                            this.lphMarginRight * 3) /
                        3
                    );
                }
            } else {
                return (this.chartDimension - this.lphMarginRight * 2) / 3;
            }
        },
        changeDateRange(item) {
            this.watchedSettings.dateRange = item.id;
            this.changeSettings({
                dateRange: this.watchedSettings.dateRange
            });
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
@import '@mds/utils-scss';
$namespace: 'mwc-markets-barometer';
$box-gap: 2px;
$chart-padding: 5px;
$label-width: 25px;
$legend-radius: 5px;
@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}
@function box-width($showLabels: true, $width: 0px) {
    // $width to handle the float value not working on IE11
    @if $showLabels == false {
        @return calc((100% - #{$box-gap} - #{$box-gap}) / 3 - #{$width});
    } @else {
        @return calc((100% - #{$label-width}) / 3 - #{$box-gap} - #{$width});
    }
}
@mixin breakpoint(
    $fontSize: 14px,
    $fontWeight: $mds-typography-font-weight-light
) {
    .#{$namespace}-chart {
        &-label {
            &-h,
            &-v {
                span {
                    font-size: $fontSize;
                }
            }
        }
        &-box {
            span {
                font-weight: $fontWeight;
            }
        }
    }
}
@function menu-left-margin($showLeftMargin: true) {
    @if $showLeftMargin == false {
        @return 0;
    } @else {
        @return calc(#{$label-width} + #{$box-gap});
    }
}

.#{$namespace} {
    &.markets-ui-container * {
        box-sizing: content-box;
    }

    &-content {
        height: 100%;
    }
    &-menu {
        height: 23px;
        padding: $mds-space-three-quarter-x 0;
        text-align: center;
        &-less490 {
            margin-left: menu-left-margin();
        }
        &-less400 {
            margin-left: menu-left-margin();
        }
        &-less285 {
            margin-left: menu-left-margin(false);
        }
        &-less225 {
            margin-left: menu-left-margin(false);
        }
        &-less140 {
            margin-left: menu-left-margin(false);
        }
    }

    &-chart {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        padding: $chart-padding;
        &-desc-hidden {
            @include mds-accessibly-hidden;
        }
        &-label {
            margin-right: $box-gap;
            margin-bottom: $box-gap;
            text-align: center;
            &-empty {
                width: $label-width;
                height: $label-width;
            }
            &-h {
                height: $label-width;
                line-height: $label-width;
                width: box-width(true, 1px);
                span {
                    font-size: 18px;
                }
            }
            &-v {
                position: relative;
                width: $label-width;
                height: box-width();
                line-height: box-width();
                span {
                    transform: rotate(-90deg);
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: -25px;
                    width: 70px;
                    word-wrap: normal;
                    font-size: 18px;
                }
            }
            &-barometerGrowth {
                margin-right: 0;
            }
            &-small {
                margin-bottom: 0;
            }
        }
        &-box {
            width: box-width(true, 1px);
            height: box-width();
            margin-right: $box-gap;
            margin-bottom: $box-gap;
            overflow: hidden;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            &-3,
            &-6,
            &-9 {
                margin-right: 0;
            }
            &-7,
            &-8,
            &-9 {
                margin-bottom: 0;
            }
            span {
                font-weight: $mds-typography-font-weight-thin;
            }
        }
        &-less490 {
            @include breakpoint(16px);
        }
        &-less400 {
            @include breakpoint(14px);
        }
        &-less285 {
            @include breakpoint(14px);
        }
        &-less225 {
            @include breakpoint(14px);
        }
        &-less140 {
            @include breakpoint(14px);
        }
    }
    &-legend {
        display: flex;
        height: 25px;
        margin: 0 auto;
        padding: $chart-padding;
        justify-content: center;
        &-placeholder {
            width: $label-width;
            margin-right: $box-gap;
        }
        &-wrap {
            width: calc(100% - #{$label-width} - #{$box-gap});
        }
        &-color,
        &-value {
            display: flex;
            flex-wrap: no-wrap;
            justify-content: space-between;
            overflow: hidden;
        }
        &-color {
            span {
                flex: 1;
                height: 10px;
                &:first-child {
                    border-top-left-radius: $legend-radius;
                    border-bottom-left-radius: $legend-radius;
                }
                &:last-child {
                    border-top-right-radius: $legend-radius;
                    border-bottom-right-radius: $legend-radius;
                }
            }
        }
        &-value {
            span {
                font-size: 12px;
            }
        }
    }
    &-date-reference {
        font-weight: $mds-typography-font-weight-light;
        font-size: 12px;
        display: flex;
        margin: 0 auto;
        height: 20px;
        padding: 0 $chart-padding;
        justify-content: center;
        text-align: center;
        &-placeholder {
            width: $label-width;
            margin-right: $box-gap;
        }
        &-wrap {
            width: calc(100% - #{$label-width} - #{$box-gap});
        }
    }
    &-color {
        &-negative {
            background-color: $mds-visualization-color-performance-negative;
            color: $mds-text-color-primary-on-dark;
        }
        &-negative-40 {
            background-color: tint(
                $mds-visualization-color-performance-negative,
                40%
            );
        }
        &-negative-70 {
            background-color: tint(
                $mds-visualization-color-performance-negative,
                70%
            );
        }
        &-base {
            background-color: $mds-visualization-color-performance-neutral;
        }
        &-positive-70 {
            background-color: tint(
                $mds-visualization-color-performance-positive,
                70%
            );
        }
        &-positive-40 {
            background-color: tint(
                $mds-visualization-color-performance-positive,
                40%
            );
        }
        &-positive {
            background-color: $mds-visualization-color-performance-positive;
            color: $mds-text-color-primary-on-dark;
        }
    }

    &__no-labels {
        .#{$namespace} {
            &-menu {
                margin-left: 0;
            }
            &-chart-box {
                width: box-width(false, 1px);
                height: box-width(false);
            }
            &-legend {
                &-wrap {
                    width: calc(100% - #{$chart-padding} - #{$chart-padding});
                }
            }
        }
    }
    &.markets-ui-container__dark-gray {
        .#{$namespace} {
            &-chart {
                &-label {
                    color: $mds-text-color-primary-on-dark;
                }
            }
            &-legend,
            &-date-reference {
                color: $mds-text-color-primary-on-dark;
            }
        }
    }
}
</style>
