var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BarometerUI", {
    ref: "UI",
    attrs: {
      skin: _vm.skin,
      dataModel: _vm.dataModel,
      showLoading: _vm.showLoading,
      errorCode: _vm.error.code,
      settings: _vm.settings,
      labels: _vm.initedLabels,
      formatter: _vm.formatter
    },
    on: { "settings-changed": _vm.changeSettings }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }