var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.classes,
      attrs: {
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading
      }
    },
    [
      !_vm.errorMessage
        ? _c(
            "div",
            {
              ref: "content",
              class: _vm.namespace("content"),
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _vm.watchedSettings.showMenu
                ? _c(
                    "div",
                    {
                      ref: "menu",
                      class: [
                        _vm.namespace("menu"),
                        _vm.chartClass
                          ? _vm.namespace("menu-" + _vm.chartClass)
                          : ""
                      ]
                    },
                    [
                      _c("markets-ui-menus", {
                        attrs: {
                          skin: _vm.watchedSettings.skin,
                          dataModel: _vm.dateRangeDataModel,
                          "aria-label": _vm.initedLabels.timePeriod
                        },
                        on: { change: _vm.changeDateRange }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "chart",
                  class: [
                    _vm.namespace("chart"),
                    _vm.chartClass
                      ? _vm.namespace("chart-" + _vm.chartClass)
                      : ""
                  ],
                  style: _vm.chartStyles,
                  attrs: {
                    role: "figure",
                    "aria-describedby": _vm.descriptionId
                  }
                },
                [
                  _c(
                    "span",
                    {
                      class: _vm.namespace("chart-desc-hidden"),
                      attrs: { id: _vm.descriptionId }
                    },
                    [_vm._v(_vm._s(_vm.chartDescription))]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.dataSet, function(d) {
                    return _c(
                      "div",
                      {
                        key: d.id,
                        class: d.cls,
                        attrs: { "aria-hidden": "true" }
                      },
                      [
                        _vm.showValues
                          ? _c("span", { style: d.style }, [
                              _vm._v(_vm._s(d.value))
                            ])
                          : _vm._e()
                      ]
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "legend",
                  class: _vm.namespace("legend"),
                  style: _vm.legendStyles,
                  attrs: { "aria-hidden": "true" }
                },
                [
                  _vm.watchedSettings.showLabels
                    ? _c("div", { class: _vm.namespace("legend-placeholder") })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { class: _vm.namespace("legend-wrap") }, [
                    _c(
                      "div",
                      { class: _vm.namespace("legend-color") },
                      _vm._l(_vm.legendColorSet, function(c) {
                        return _c("span", { key: c, class: _vm.namespace(c) })
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: _vm.namespace("legend-value") },
                      _vm._l(_vm.legendValueSet, function(v) {
                        return _c("span", { key: v.id }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(v.value) +
                              "\n                    "
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "dateReference",
                  class: [_vm.namespace("date-reference")]
                },
                [
                  _vm.watchedSettings.showLabels
                    ? _c("div", {
                        class: _vm.namespace("date-reference-placeholder")
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { class: _vm.namespace("date-reference-wrap") }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.dataAsOf) +
                        "\n            "
                    )
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }