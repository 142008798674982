const ranges = {
    oneDay: [
        [Number.NEGATIVE_INFINITY, -1.25],
        [-1.249, -0.625],
        [-0.624, -0.001],
        [0, 0],
        [0.001, 0.624],
        [0.625, 1.249],
        [1.25, Number.POSITIVE_INFINITY]
    ],
    oneWeek: [
        [Number.NEGATIVE_INFINITY, -1.25],
        [-1.249, -0.625],
        [-0.624, -0.001],
        [0, 0],
        [0.001, 0.624],
        [0.625, 1.249],
        [1.25, Number.POSITIVE_INFINITY]
    ],
    oneMonth: [
        [Number.NEGATIVE_INFINITY, -4.0],
        [-3.999, -2.0],
        [-1.999, -0.001],
        [0, 0],
        [0.001, 1.999],
        [2.0, 3.999],
        [4.0, Number.POSITIVE_INFINITY]
    ],
    threeMonth: [
        [Number.NEGATIVE_INFINITY, -8.0],
        [-7.999, -2.667],
        [-2.666, -0.001],
        [0, 0],
        [0.001, 2.666],
        [2.667, 7.999],
        [8.0, Number.POSITIVE_INFINITY]
    ],
    oneYear: [
        [Number.NEGATIVE_INFINITY, -20.0],
        [-19.999, -6.667],
        [-6.666, -0.001],
        [0, 0],
        [0.001, 6.666],
        [6.667, 19.999],
        [20.0, Number.POSITIVE_INFINITY]
    ],
    threeYear: [
        [Number.NEGATIVE_INFINITY, -20.0],
        [-19.999, -6.667],
        [-6.666, -0.001],
        [0, 0],
        [0.001, 6.666],
        [6.667, 19.999],
        [20.0, Number.POSITIVE_INFINITY]
    ]
};

export const colorSet = [
    'color-negative',
    'color-negative-40',
    'color-negative-70',
    'color-base',
    'color-positive-70',
    'color-positive-40',
    'color-positive'
];

export function getFillColor(dateRange, value) {
    const range = ranges[dateRange];
    if (range) {
        for (let i = 0; i < range.length; i++) {
            if (
                value >= range[i][0] &&
                (value <= range[i][1] || range[i][1] === undefined)
            ) {
                return colorSet[i];
            }
        }
    }
    return colorSet[3];
}

export function getRange(dateRange) {
    const range = ranges[dateRange];
    return [range[0][1], range[range.length - 1][0]];
}
